import Demo1 from "./demo1";
import Demo2 from "./demo2";
import Demo3 from "./demo3";
import Demo4 from "./demo4";
import Demo5 from "./demo5";

if (document.querySelector(".demo-1")) {
  const demo1 = new Demo1();
}

if (document.querySelector(".demo-2")) {
  const demo2 = new Demo2();
}

if (document.querySelector(".demo-3")) {
  const demo3 = new Demo3();
}

if (document.querySelector(".demo-4")) {
  const demo4 = new Demo4();
}

if (document.querySelector(".demo-5")) {
  const demo5 = new Demo5();
}
