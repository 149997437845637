const initPageTransitions = () => {
  setTimeout(() => document.body.classList.add("render"), 60);
  const navdemos = Array.from(document.querySelectorAll(".demos__links .demo"));
  const total = navdemos.length;
  const current = navdemos.findIndex(el =>
    el.classList.contains("demo--current")
  );
  const navigate = linkEl => {
    document.body.classList.remove("render");
    document.body.addEventListener(
      "transitionend",
      () => (window.location = linkEl.href)
    );
  };
  navdemos.forEach(link =>
    link.addEventListener("click", ev => {
      ev.preventDefault();
      navigate(ev.currentTarget);
    })
  );
  document.addEventListener("keydown", ev => {
    const keyCode = ev.keyCode || ev.which;
    let linkEl;
    if (keyCode === 37) {
      linkEl = current > 0 ? navdemos[current - 1] : navdemos[total - 1];
    } else if (keyCode === 39) {
      linkEl = current < total - 1 ? navdemos[current + 1] : navdemos[0];
    } else {
      return false;
    }
    navigate(linkEl);
  });
};

export default initPageTransitions;
